import React from "react"
import InstagramIcon from "@material-ui/icons/Instagram"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EmailIcon from "@material-ui/icons/Email"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-social">
          <div className="align-footer-item">
            <p className="align-footer-item">Follow me on </p>
            <InstagramIcon
              className="footer-icon"
              fontSize="large"
              style={{ color: "#894f93" }}
            />
            <a
              className="email-font"
              href="https://www.instagram.com/shampinesupernova/"
              target="_blank"
              rel="noreferrer noopener"
            >
              @shampinesupernova
            </a>
          </div>
        </div>
        <div className="footer-location">
          <p className="align-footer-item">
            <LocationOnIcon className="footer-icon" fontSize="large" />
            Los Angeles, CA
          </p>
        </div>
        <div className="footer-email">
          <div className="align-footer-item">
            <EmailIcon className="footer-icon" fontSize="large" />
            <a
              className="email-font"
              href="mailto:catie@shampinesupernova.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              catie@shampinesupernova.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
