import React, { useState } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import MenuIcon from "@material-ui/icons/Menu"
import LogoSmall from "../images/LOGO-small.png"
import MenuDivider from "../images/menu-divider.png"
import TopLeft from "../images/menu-top-left.png"
import TopRight from "../images/menu-top-right.png"
import BottomLeft from "../images/menu-bottom-left.png"
import BottomRight from "../images/menu-bottom-right.png"

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    paddingLeft: 50,
  },
})

export default function TemporaryDrawer() {
  const classes = useStyles()
  const [state, setState] = useState({
    left: false,
  })
  const [open, setOpen] = useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const list = (anchor) => (
    <div>
      <div className="menu-drawer">
        <div className="menu-corners">
          <img src={TopLeft} alt="menu-corner" className="top-left" />
          <img src={TopRight} alt="menu-corner" className="top-right" />
        </div>
        <div className="logo-menu">
          <Link to="/">
            <img src={LogoSmall} alt="logo" className="menu-image" />
          </Link>
        </div>
        <div className="menu-divider">
          <img
            src={MenuDivider}
            alt="menu-divider"
            className="menu-divider-image"
          />
        </div>
        <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === "top" || anchor === "bottom",
          })}
          role="presentation"
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List className="menu-opened">
            <Link to="/">
              <ListItem button onClick={toggleDrawer(anchor, false)}>
                <h3 className="menu-text">HOME</h3>
              </ListItem>
            </Link>
            <Link to="/about">
              <ListItem button onClick={toggleDrawer(anchor, false)}>
                <h3 className="menu-text">ABOUT</h3>
              </ListItem>
            </Link>
            <ListItem button onClick={handleClick}>
              <h3 className="menu-text">MY MAGIC</h3>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/reiki">
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <h3 className="menu-text">REIKI</h3>
                  </ListItem>
                </Link>
                <Link to="/tarot">
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <h3 className="menu-text">TAROT</h3>
                  </ListItem>
                </Link>
                <Link to="schedule">
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <h3 className="menu-text">SCHEDULE APPOINTMENT</h3>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            <Link to="/contact">
              <ListItem button onClick={toggleDrawer(anchor, false)}>
                <h3 className="menu-text">CONTACT/FAQ</h3>
              </ListItem>
            </Link>
          </List>
          <div className="menu-divider">
            <img
              src={MenuDivider}
              alt="menu-divider"
              className="menu-divider-image"
            />
          </div>
        </div>
      </div>
      <div className="menu-corners">
        <img src={BottomLeft} alt="menu-corner" className="bottom-left" />
        <img src={BottomRight} alt="menu-corner" className="bottom-right" />
      </div>
    </div>
  )

  return (
    <div className="menu-bar">
      <div className="bar-content">
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon fontSize="large" style={{ color: "#efa4b2" }}>
                {anchor}
              </MenuIcon>
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
