import React from "react"
import ScrollToTop from "./components/routes/ScrollToTop"
import Layout from "./components/Layout"
import AboutBlurb from "./components/home/AboutBlurb"
import ServicesCards from "./components/home/ServicesCards"
import Logo from "./images/home-page-icon.png"
import Divider from "./images/metal-golden-dividers-02.png"

export default function Home({ title, description, ...props }) {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div>
          <div className="logo-home">
            <img src={Logo} alt="logo" className="home-logo-size" />
          </div>
          <h3 className="subtitle">{description}</h3>
        </div>
        <div>
          <AboutBlurb />
        </div>
        <div className="divider-placememt">
          <img src={Divider} alt="divider" className="section-divider" />
        </div>
        <div>
          <ServicesCards />
        </div>
      </Layout>
    </>
  )
}
