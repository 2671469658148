import React from "react"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import HomeCH from "../../images/home-ch.png"

export default function AboutBlurb() {
  return (
    <div className="about-blurb">
      <div className="blurb">
        <div className="blurb-flex">
          <img className="chapter-head" src={HomeCH} alt="home-ch" />
          <h2 className="title-align">
            ife doesn’t always feel like an extravagant party. Sometimes we need
            to seek out support and clarity.
          </h2>
        </div>
      </div>
      <h2 className="subtitle-center">I can help you there.</h2>
      <div className="learn-more-about">
        <Link to="/about">
          <Button
            variant="contained"
            disableElevation
            size="large"
            style={{
              backgroundColor: "#efa4b2",
              color: "#894f93",
              fontSize: "25px",
              paddingBottom: 13,
            }}
          >
            ABOUT
          </Button>
        </Link>
      </div>
    </div>
  )
}
