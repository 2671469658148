import React from "react"
import ScrollToTop from "../../components/routes/ScrollToTop"
import Layout from "../../components/Layout"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import TarotImage from "../../images/handdrawn-cards.png"
import Divider from "../../images/metal-golden-dividers-08.png"
import CardDivider from "../../images/card-divider.png"
import TarotCards from "../../components/shop/TarotCards"
import TarotCH from "../../images/tarot-ch.png"

export default function Tarot() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div className="head-image">
          <img className="service-tarot-image" src={TarotImage} alt="tarot" />
        </div>
        <div className="title-center">
          <h1 className="service-title">
            T<span className="title-align">arot</span>
          </h1>
        </div>
        <div className="divider-placememt">
          <img src={Divider} alt="divider" className="section-divider" />
        </div>
        <div className="product-description">
          <p>
            <img className="chapter-head" src={TarotCH} alt="home-ch" />
            arot is not just a way to find out if your partner is cheating on
            you(lol)! It’s a sacred form of divination used to connect and bring
            clarity to those who use it. Laying all the cards on the table,
            literally, is one of the best ways to gain understanding about any
            issue or question that you may have.
          </p>
          <div className="card-divider">
            <img src={CardDivider} alt="tarot" />
          </div>
        </div>
        <TarotCards />
        <div className="shop-button-group">
          <div className="a-button">
            <Link to="/reiki">
              <Button
                size="large"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                }}
              >
                Reiki
              </Button>
            </Link>
          </div>
          <div className="a-button">
            <Link to="/contact">
              <Button
                size="large"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                }}
              >
                Contact/FAQ
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  )
}
