import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import SixtyMinuteCH from "../../images/60-ch.png"
import ThirtyMinuteCH from "../../images/30-ch.png"
import FifteenMinuteCH from "../../images/15-ch.png"

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    marginTop: 15,
    width: 100,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
  },
  header: {
    marginTop: -25,
  },
  title: {
    fontSize: 25,
  },
}))

export default function TarotCards() {
  const classes = useStyles()

  return (
    <>
      <div className="services-cards">
        <div className="title-center">
          <h1>
            S<span className="title-align">chedule</span> A R
            <span className="title-align">eading</span>
          </h1>
        </div>
        <Container maxWidth="md">
          <div className={classes.grid}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <img
                        src={FifteenMinuteCH}
                        alt="fifteen-minute"
                        className={classes.avatar}
                      />
                    }
                    title={
                      <h4 className={classes.title}>MINUTE TAROT READING</h4>
                    }
                    className={classes.header}
                  />
                  <CardContent>
                    <p className="appointment-description">
                      If you’re in need of a quick dose of clarity, this is the
                      reading for you!
                    </p>
                    <CardActions className={classes.buttonGroup}>
                      <Link to="/schedule">
                        <Button size="large" color="primary">
                          Schedule Appointment
                        </Button>
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <img
                        src={ThirtyMinuteCH}
                        alt="thirty-minute"
                        className={classes.avatar}
                      />
                    }
                    title={
                      <h4 className={classes.title}>MINUTE TAROT READING</h4>
                    }
                    className={classes.header}
                  />
                  <CardContent>
                    <p className="appointment-description">
                      This is slightly more in depth. We will pull a few more
                      cards and gather more info about your situation!
                    </p>
                    <CardActions className={classes.buttonGroup}>
                      <Link to="/schedule">
                        <Button size="large" color="primary">
                          Schedule Appointment
                        </Button>
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <img
                        src={SixtyMinuteCH}
                        alt="sixty-minute"
                        className={classes.avatar}
                      />
                    }
                    title={
                      <h4 className={classes.title}>MINUTE TAROT READING</h4>
                    }
                    className={classes.header}
                  />
                  <CardContent>
                    <p className="appointment-description">
                      This is a fully in depth analysis of your current
                      situation. We will do a full spread and talk at length
                      about what’s happening and how to handle it!
                    </p>
                    <CardActions className={classes.buttonGroup}>
                      <Link to="/schedule">
                        <Button size="large" color="primary">
                          Schedule Appointment
                        </Button>
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  )
}
