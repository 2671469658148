import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
    "&:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    display: "block",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default function FaqAccordion() {
  const [expanded, setExpanded] = useState()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <div>
        <div className="title-center">
          <h1>FAQ</h1>
        </div>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2 className="question">
              When am I able to schedule a reading or session with you?
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            <p className="answer">
              I provide Tarot Readings and Reiki Sessions from Tuesday through
              Saturday, 11-7pm. My listed schedule should display my openings
              during these hours.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2 className="question">What is your cancellation policy?</h2>
          </AccordionSummary>
          <AccordionDetails>
            <p className="answer">
              I have a 24 hour cancelation policy. I'm happy to provide a full
              refund for any cancellation with 24 hours or more. All
              cancellations within 24 hours notice of your scheduled appointment
              and missed appointments are nonrefundable.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2 className="question">
              Can I schedule an appointment with you outside of your normal
              hours?
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            <p className="answer">
              I'm more than happy to schedule readings outside of my normal
              hours. Please contact me directly at{" "}
              <a href="mailto:catie@shampinesupernova.com">
                catie@shampinesupernova.com
              </a>
              . Additional fees may incur depending on the reading or session,
              and time that you've requested.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2 className="question">
              Is there anything I should do to prepare for my Reading or
              Session?
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            <p className="answer">
              Taking some deep breaths and relaxing your mind before a reading
              or session will definitely help create a clear channel but it's
              not mandatory. Just a friendly suggestion!
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2 className="question">What is your COVID policy?</h2>
          </AccordionSummary>
          <AccordionDetails>
            <p className="answer">
              For the safety of all of my clients, all Reiki Sessions and Tarot
              Readings will be conducted virtually over Zoom. A Zoom link will
              automatically be provided when you schedule your appointment. This
              policy is subject to change with the lowering of case numbers in
              Los Angeles County, but will remain for the forseeable future.
              <br />
              <br />I appreciate your understanding and respect of this policy.
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
