import React from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TarotImage from "../../images/handdrawn-cards.png"
import RaikiImage from "../../images/handdrawn-eye.png"
import CardDivider from "../../images/card-divider.png"

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },

  card: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
  },
}))

export default function ServicesCards() {
  const classes = useStyles()

  return (
    <>
      <div className="services-cards">
        <h1 className="title-center">
          S<span className="title-align">ervices</span>
        </h1>
        <div className={classes.grid}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card
                className={classes.card}
                style={{
                  display: "felx",
                  justifyContent: "center",
                }}
              >
                <img
                  className="service-reiki-image"
                  src={RaikiImage}
                  alt="reiki"
                />
                <CardContent>
                  <h2>Reiki</h2>
                  <div className="card-divider">
                    <img src={CardDivider} alt="tarot" />
                  </div>
                </CardContent>

                <CardActions className={classes.buttonGroup}>
                  <Link to="/reiki">
                    <Button size="large" color="primary">
                      Learn More
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="large" color="primary">
                      Schedule Appointment
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                className={classes.card}
                style={{
                  display: "felx",
                  justifyContent: "center",
                }}
              >
                <img
                  className="service-tarot-image"
                  src={TarotImage}
                  alt="tarot"
                />
                <CardContent>
                  <h2>Tarot</h2>
                  <div className="card-divider">
                    <img src={CardDivider} alt="tarot" />
                  </div>
                </CardContent>
                <CardActions className={classes.buttonGroup}>
                  <Link to="/tarot">
                    <Button size="large" color="primary">
                      Learn More
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="large" color="primary">
                      Schedule Appointment
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}
