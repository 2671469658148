import React from "react"
import { Link } from "react-router-dom"
import ScrollToTop from "../../components/routes/ScrollToTop"
import Layout from "../../components/Layout"
import Divider from "../../images/metal-golden-dividers-10.png"
import MenuDivider from "../../images/menu-divider.png"
import TopLeft from "../../images/menu-top-left.png"
import TopRight from "../../images/menu-top-right.png"
import BottomLeft from "../../images/menu-bottom-left.png"
import BottomRight from "../../images/menu-bottom-right.png"
import Button from "@material-ui/core/Button"
import CardDivider from "../../images/card-divider.png"

export default function Schedule() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div className="title-center">
          <h1 className="service-title">
            S<span className="title-align">chedule</span> A
            <span className="title-align">ppointment</span>
          </h1>
          <div className="divider-placememt">
            <img src={Divider} alt="divider" className="section-divider" />
          </div>
          <div className="scheduler">
            <div className="menu-corners">
              <img src={TopLeft} alt="menu-corner" className="top-left" />
              <img src={TopRight} alt="menu-corner" className="top-right" />
            </div>
            <div className="scheduler-bg">
              <div className="menu-divider">
                <img
                  src={MenuDivider}
                  alt="menu-divider"
                  className="menu-divider-image"
                />
              </div>
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=21818890"
                title="Schedule Appointment"
                width="100%"
                height="1200"
                frameBorder="0"
              ></iframe>
              <script
                src="https://embed.acuityscheduling.com/js/embed.js"
                type="text/javascript"
              ></script>
              <div className="menu-divider">
                <img
                  src={MenuDivider}
                  alt="menu-divider"
                  className="menu-divider-image"
                />
              </div>
            </div>
            <div className="menu-corners">
              <img src={BottomLeft} alt="menu-corner" className="bottom-left" />
              <img
                src={BottomRight}
                alt="menu-corner"
                className="bottom-right"
              />
            </div>
          </div>
        </div>
        <div className="card-divider">
          <img src={CardDivider} alt="tarot" />
        </div>
        <div className="about-button-group">
          <div className="a-button">
            <Link to="/contact">
              <Button
                size="large"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                }}
              >
                Contact/FAQ
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  )
}
