import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import SixtyMinuteCH from "../../images/60-ch.png"

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    marginTop: 15,
    width: 100,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
  },
  header: {
    marginTop: -25,
  },
  title: {
    fontSize: 25,
  },
}))

export default function RaikiCards() {
  const classes = useStyles()

  return (
    <>
      <div className="services-cards">
        <div className="title-center">
          <h1>
            S<span className="title-align">chedule</span> A S
            <span className="title-align">ession</span>
          </h1>
        </div>
        <Container maxWidth="md">
          <div className={classes.grid}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <img
                        src={SixtyMinuteCH}
                        alt="sixty-minute"
                        className={classes.avatar}
                      />
                    }
                    title={
                      <h4 className={classes.title}>MINUTE REIKI SESSION</h4>
                    }
                    className={classes.header}
                  />
                  <CardContent>
                    <p className="appointment-description">
                      This full session involves a cleansing of all your
                      chakras, or energy centers, as well as focused healing on
                      any areas that require a little more love. I also
                      incorporate elements of sound healing and meditation, as
                      well as intention setting for the session and beyond.
                    </p>
                    <CardActions className={classes.buttonGroup}>
                      <Link to="/schedule">
                        <Button size="large" color="primary">
                          Schedule Appointment
                        </Button>
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  )
}
