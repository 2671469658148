import React from "react"
import emailjs from "emailjs-com"
import Button from "@material-ui/core/Button"
import MenuDivider from "../../images/menu-divider.png"
import TopLeft from "../../images/menu-top-left.png"
import TopRight from "../../images/menu-top-right.png"
import BottomLeft from "../../images/menu-bottom-left.png"
import BottomRight from "../../images/menu-bottom-right.png"

export default function ContactForm() {
  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_5mgm5pd",
        "template_nu0mv1f",
        e.target,
        "user_9BASI5B7S3p0v11rS7XUf"
      )
      .then(
        (result) => {
          alert("Your Message was received! Thank you!")
        },
        (error) => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  return (
    <div className="contact-component">
      <div className="menu-corners">
        <img src={TopLeft} alt="menu-corner" className="top-left" />
        <img src={TopRight} alt="menu-corner" className="top-right" />
      </div>
      <div>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="contact-form">
            <div className="title-center">
              <h1 className="page-title">Contact</h1>
            </div>
            <div className="menu-divider">
              <img
                src={MenuDivider}
                alt="menu-divider"
                className="menu-divider-image"
              />
            </div>
            <div className="contact-form-one">
              <div className="form-item-one">
                <input
                  className="input-item-one"
                  type="text"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-item-one">
                <input
                  className="input-item-one"
                  type="email"
                  name="email"
                  placeholder="Email*"
                  required
                />
              </div>
            </div>
            <div className="contact-form-two">
              <textarea
                className="input-item-two"
                name="message"
                placeholder="Message*"
                required
              />
            </div>
            <div className="contact-form-button">
              <Button
                variant="contained"
                disableElevation
                size="large"
                type="submit"
                value="Send"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                  border: "2px solid #894f93",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="menu-corners">
        <img src={BottomLeft} alt="menu-corner" className="bottom-left" />
        <img src={BottomRight} alt="menu-corner" className="bottom-right" />
      </div>
    </div>
  )
}
