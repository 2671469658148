import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import App from "./App"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Reiki from "./pages/shop/Reiki"
import Tarot from "./pages/shop/Tarot"
import Schedule from "./pages/shop/Schedule"
import "./styles/styles.css"
// import { AuthProvider } from "./dev/AuthContext"
// import PrivateRoute from "./dev/PrivateRoute"
// import SignIn from "./dev/SignIn"

ReactDOM.render(
  <Router>
    {/* <AuthProvider> */}
    <Switch>
      {/* <Route path="/signin" component={SignIn} /> */}
      <Route exact path="/" component={App} />
      <Route path="/about" component={About} />
      <Route path="/reiki" component={Reiki} />
      <Route path="/tarot" component={Tarot} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/contact" component={Contact} />
    </Switch>
    {/* </AuthProvider> */}
  </Router>,
  document.getElementById("root")
)
