import React from "react"
import ScrollToTop from "../components/routes/ScrollToTop"
import { Link } from "react-router-dom"
import Layout from "../components/Layout"
import Button from "@material-ui/core/Button"
import Divider from "../images/metal-golden-dividers-04.png"
import CardDivider from "../images/card-divider.png"
import AboutCH from "../images/about-ch.png"

export default function About() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div>
          <div className="title-center">
            <h1 className="page-title">
              A<span className="title-align">bout</span>
            </h1>
          </div>
          <div className="divider-placememt">
            <img src={Divider} alt="divider" className="section-divider" />
          </div>
        </div>
        <div className="page-body">
          <p>
            <img className="chapter-head" src={AboutCH} alt="home-ch" />
            ’m Catie! Other than being the life of the party, I’m a dancer,
            writer, binge-watcher, and most importantly, a healer. When I’m not
            out dancing and popping champagne, I’m finding ways to connect
            deeply to spirit in order to help heal others. I started using tarot
            cards when I was around fourteen years old. At sleepovers, everyone
            would wait their turn, mom’s Yankee Candles burning, as I told them
            if their crush liked them or not! Ever since, I have continued to
            develop my skills and work with tarot in my own life.
            <br />
            <br />
            As I continued to grow spiritually, Reiki found me. Practicing Reiki
            is a great honor and privilege. It has deepened my connection to
            spirit and increased my ability to heal others. It’s a magical
            experience-- and as a huge Harry Potter fan, magic is what I’m
            after!
          </p>
        </div>
        <div className="card-divider">
          <img src={CardDivider} alt="tarot" />
        </div>
        <div>
          <div className="about-button-group">
            <div className="a-button">
              <Link to="/schedule">
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#efa4b2",
                    color: "#894f93",
                    fontSize: "25px",
                    paddingBottom: 13,
                  }}
                >
                  Schedule Appointment
                </Button>
              </Link>
            </div>
            <div className="a-button">
              <Link to="/contact">
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#efa4b2",
                    color: "#894f93",
                    fontSize: "25px",
                    paddingBottom: 13,
                  }}
                >
                  Contact/FAQ
                </Button>
              </Link>
            </div>
          </div>
          <div className="page-body">
            <p>
              If you have any specific questions or want to get to know me more,
              feel free to connect via email or check out my social media!
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}
