import React from "react"
import ScrollToTop from "../../components/routes/ScrollToTop"
import Layout from "../../components/Layout"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import RaikiImage from "../../images/handdrawn-eye.png"
import Divider from "../../images/metal-golden-dividers-06.png"
import CardDivider from "../../images/card-divider.png"
import ReikiCards from "../../components/shop/ReikiCards"
import ReikiCH from "../../images/reiki-ch.png"

export default function Reiki() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div className="head-image">
          <img className="service-reiki-image" src={RaikiImage} alt="reiki" />
        </div>
        <div className="title-center">
          <h1 className="service-title">
            R<span className="title-align">eiki</span>
          </h1>
        </div>
        <div className="divider-placememt">
          <img src={Divider} alt="divider" className="section-divider" />
        </div>
        <div className="product-description">
          <p>
            <img className="chapter-head" src={ReikiCH} alt="home-ch" />
            lso known as energy healing, Reiki is a Japanese practice which
            involves a practitioner (moi!) healing through the hands. Basically,
            I can channel energy (oooh!) and help heal your body, mind, and
            spirit. It’s also extremely relaxing and great for stress relief. I
            recommend Reiki if you’re feeling a little stuck in life, feeling
            physical pain, or just in need of some healing energy.
          </p>
          <div className="card-divider">
            <img src={CardDivider} alt="tarot" />
          </div>
        </div>
        <ReikiCards />
        <div className="shop-button-group">
          <div className="a-button">
            <Link to="/tarot">
              <Button
                size="large"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                }}
              >
                Tarot
              </Button>
            </Link>
          </div>
          <div className="a-button">
            <Link to="/contact">
              <Button
                size="large"
                style={{
                  backgroundColor: "#efa4b2",
                  color: "#894f93",
                  fontSize: "25px",
                  paddingBottom: 13,
                }}
              >
                Contact/FAQ
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  )
}
