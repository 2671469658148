import React from "react"
import Menu from "./Menu"
import Footer from "./Footer"
import Container from "@material-ui/core/Container"
// import SignOut from "../dev/SignOut"

export default function Layout({ children, ...props }) {
  return (
    <div>
      <main>
        <Menu />
        <Container maxWidth="lg">
          <section className="body-wrapper">
            <div>{children}</div>
          </section>
        </Container>
      </main>
      {/* <SignOut /> */}
      <Footer />
    </div>
  )
}
