import React from "react"
import ScrollToTop from "../components/routes/ScrollToTop"
import Layout from "../components/Layout"
import ContactForm from "../components/contact/ContactForm"
import FaqAccordion from "../components/contact/FaqAccordion"
import Divider from "../images/metal-golden-dividers-03.png"

export default function Contact({ title, ...props }) {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <ContactForm />
        <div className="divider-placememt">
          <img src={Divider} alt="divider" className="section-divider" />
        </div>
        <FaqAccordion />
      </Layout>
    </>
  )
}
